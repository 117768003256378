'use client'

import React, { useState } from 'react'
import { motion, useViewportScroll, useTransform } from 'framer-motion'
import { FiCalendar, FiTruck, FiTool, FiBarChart2, FiMenu, FiX } from 'react-icons/fi'
import { Button } from './componenets/button'
import DriverApp from './DriverApp.png'
import ServiceApp from './ServiceApp.png'

export default function ModernLandingPage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { scrollYProgress } = useViewportScroll()
  const backgroundY = useTransform(scrollYProgress, [0, 1], ['0%', '100%'])
  const textY = useTransform(scrollYProgress, [0, 1], ['0%', '200%'])

  const redirectToApp = () => {
    window.location.href = 'https://app.servicegest.ro'
  }

  const redirectToApp2 = () => {
    window.location.href = 'https://app.servicegest.ro/login'
  }

  const stats = [
    { icon: FiCalendar, value: '5,000+', label: 'Programări Lunare' },
    { icon: FiTruck, value: '4,000+', label: 'Șoferi Activi' },
    { icon: FiTool, value: '20+', label: 'Service-uri Partenere' },
    { icon: FiBarChart2, value: '98%', label: 'Satisfacție Clienți' },
  ]

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-slate-900 via-purple-900 to-slate-900 text-white overflow-hidden">
      {/* Animated background elements */}
      <div className="fixed inset-0 z-0 overflow-hidden pointer-events-none">
        <motion.div
          className="absolute inset-0 opacity-20"
          animate={{
            scale: [1, 1.1, 1],
            rotate: [0, 10, 0],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
        >
          <div className="w-full h-full bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1MDAiIGhlaWdodD0iNTAwIj48ZmlsdGVyIGlkPSJub2lzZSIgeD0iMCIgeT0iMCIPjxmZVR1cmJ1bGVuY2UgdHlwZT0iZnJhY3RhbE5vaXNlIiBiYXNlRnJlcXVlbmN5PSIuNyIgbnVtT2N0YXZlcz0iMTAiIHN0aXRjaFRpbGVzPSJzdGl0Y2giLz48ZmVCbGVuZCBtb2RlPSJzY3JlZW4iLz48L2ZpbHRlcj48cmVjdCB3aWR0aD0iNTAwIiBoZWlnaHQ9IjUwMCIgZmlsdGVyPSJ1cmwoI25vaXNlKSIgb3BhY2l0eT0iMC40Ii8+PC9zdmc+')]"></div>
        </motion.div>
      </div>

      <header className="fixed w-full z-50 bg-slate-900 bg-opacity-50 backdrop-filter backdrop-blur-lg">
        <nav className="mx-auto px-6 py-4 flex justify-between items-center">
          <motion.h1 
            className="text-lg md:text-xl font-semibold"
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5 }}
          >
            Servicegest
          </motion.h1>
          <div className="hidden md:flex space-x-2">
            <Button
              variant="default"
              onClick={redirectToApp}
            >
              Programare Șofer
            </Button>
            <Button
              variant="outline"
              onClick={redirectToApp2}
            >
              Logare Service
            </Button>
          </div>
          <button
            className="md:hidden text-white focus:outline-none"
            onClick={toggleMenu}
          >
            {isMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </nav>
        {isMenuOpen && (
          <motion.div
            className="md:hidden bg-slate-800 py-4"
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <button
              className="block w-full text-left px-6 py-2 text-white hover:bg-slate-700 transition-colors duration-300"
              onClick={() => {
                redirectToApp()
                toggleMenu()
              }}
            >
              Programare Șofer
            </button>
            <button
              className="block w-full text-left px-6 py-2 text-white hover:bg-slate-700 transition-colors duration-300"
              onClick={() => {
                redirectToApp2()
                toggleMenu()
              }}
            >
              Logare Service
            </button>
          </motion.div>
        )}
      </header>

      <main>
        <section className="relative min-h-screen flex items-center justify-center overflow-hidden">
          <motion.div 
            className="absolute inset-0 z-0"
            style={{ 
              backgroundImage: 'url("/placeholder.svg?height=1080&width=1920")',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              y: backgroundY
            }}
          />
          <div className="absolute inset-0 bg-slate-900 opacity-75 z-10"></div>
          <div className="container mx-auto px-6 relative z-20">
            <motion.div
              className="text-center"
              style={{ y: textY }}
            >
      <h2 className="text-4xl md:text-5xl lg:text-7xl font-bold mb-6">Soluția completă pentru șoferi și atelier auto</h2>
      <p className="text-lg md:text-xl lg:text-2xl mb-8 max-w-3xl mx-auto">
                Conectăm șoferii cu service-urile auto într-un mod eficient și modern. 
                Gestionează-ți programările cu ușurință și crește-ți afacerea.
              </p>
              <Button
                variant="default"
                size="lg"
                onClick={redirectToApp}
              >
                Începe Acum
              </Button>
            </motion.div>
          </div>
        </section>

        <section className="py-20 bg-slate-800">
          <div className="container mx-auto px-6">
            <h3 className="text-3xl font-bold mb-12 text-center">Impactul Nostru în Cifre</h3>
            <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
              {stats.map((stat, index) => (
                <motion.div 
                  key={index}
                  className="text-center"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <stat.icon className="text-5xl mb-4 mx-auto text-purple-500" />
                  <h4 className="text-4xl font-bold mb-2">{stat.value}</h4>
                  <p className="text-slate-400">{stat.label}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-20 relative overflow-hidden">
          <div className="container mx-auto px-6">
            <div className="flex flex-col md:flex-row items-center">
              <motion.div 
                className="md:w-1/2 mb-10 md:mb-0"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h3 className="text-3xl font-bold mb-6">Pentru Șoferi</h3>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <FiCalendar className="text-purple-500 mr-2" />
                    <span>Programări rapide și ușoare</span>
                  </li>
                  <li className="flex items-center">
                    <FiTool className="text-purple-500 mr-2" />
                    <span>Acces la o rețea de service-uri de încredere</span>
                  </li>
                  <li className="flex items-center">
                    <FiBarChart2 className="text-purple-500 mr-2" />
                    <span>Istoricul complet al serviciilor</span>
                  </li>
                </ul>
              </motion.div>
              <motion.div 
                className="md:w-1/3"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <div className="bg-slate-700 rounded-lg shadow-xl">
                  <img src={DriverApp} alt="Driver App Interface" width={500} height={300} className="rounded-lg" />
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="py-20 bg-slate-800 relative overflow-hidden">
          <div className="flex justify-center gap-4 mx-auto px-6">
            <div className="flex flex-col md:flex-row items-center gap-10">
              <motion.div 
                className=" order-2 md:order-1"
                initial={{ opacity: 0, x: -50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                <div className="bg-slate-700 rounded-lg shadow-xl">
                  <img src={ServiceApp} alt="Service Dashboard" width={800} height={500} className="rounded-lg" />
                </div>
              </motion.div>
              <motion.div 
                className="md:w-1/2 mb-10 md:mb-0 order-1 md:order-2"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h3 className="text-3xl font-bold mb-6">Pentru Service-uri</h3>
                <ul className="space-y-4">
                  <li className="flex items-center">
                    <FiTruck className="text-purple-500 mr-2" />
                    <span>Gestionare eficientă a programărilor</span>
                  </li>
                  <li className="flex items-center">
                    <FiBarChart2 className="text-purple-500 mr-2" />
                    <span>Analize detaliate și rapoarte</span>
                  </li>
                  <li className="flex items-center">
                    <FiCalendar className="text-purple-500 mr-2" />
                    <span>Optimizarea fluxului de lucru</span>
                  </li>
                </ul>
              </motion.div>
            </div>
          </div>
        </section>

        <section className="py-20">
          <div className="container mx-auto px-6 text-center">
            <h3 className="text-3xl font-bold mb-8">Pregătit să Revoluționezi Programările Auto?</h3>
            <Button
              variant="default"
              size="lg"
              onClick={redirectToApp}
            >
              Încearcă Servicegest Gratuit
            </Button>
          </div>
        </section>
      </main>

      <footer className="bg-slate-900 py-8">
        <div className="container mx-auto px-6 text-center text-slate-400">
          <p>&copy; {new Date().getFullYear()} Servicegest. Toate drepturile rezervate.</p>
        </div>
      </footer>
    </div>
  )
}