import React from 'react';
import ModernLandingPage from './pages/LandingPage';

function App() {
  return (
    <div>
      <ModernLandingPage />
    </div>
  );
}

export default App;