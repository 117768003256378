import React from 'react'

export function Button({ children, variant = 'default', size = 'md', onClick, className = '', ...props }) {
  const baseStyles = 'font-semibold rounded-full transition-colors duration-300'
  const variantStyles = {
    default: 'bg-purple-600 text-white hover:bg-purple-700',
    outline: 'bg-transparent border border-slate-300 text-white hover:bg-slate-700'
  }
  const sizeStyles = {
    md: 'px-6 py-2',
    lg: 'px-8 py-3 text-lg'
  }

  const buttonStyles = `${baseStyles} ${variantStyles[variant]} ${sizeStyles[size]} ${className}`

  return (
    <button className={buttonStyles} onClick={onClick} {...props}>
      {children}
    </button>
  )
}